import React from 'react'
import RootComponent from './RootComponent'

const App: React.FC = () => {
    return (
        <RootComponent/>
    )
}

export default App
