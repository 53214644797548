import {ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache} from '@apollo/client'
import {CJ_TOKEN, CJ_URL} from "./api-constants"

const httpLink = new HttpLink({ uri: CJ_URL })

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            authorization: `Bearer ${CJ_TOKEN}`,
        },
    })
    return forward(operation);
})

export const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),

})