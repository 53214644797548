import {Container} from "react-bootstrap";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Privacy: React.FC = () => {
  return (
    <>
      <Header/>
      <Container>
        <section>
          <h3>Privacy Policy for AllBrand.site</h3>
          <p>Last Updated: 21-Jan-2024</p>
          <p>
            Thank you for visiting AllBrand.site. Your privacy is important to us. This Privacy Policy outlines how your
            personal information is collected, used, and protected by us.
          </p>
          <ul className="list-group">
            <li className="list-group-item">
              <span>1. Information We Collect:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  1.1 Personal Information:<br/>
                  - When you visit our website, we do not collect any personally identifiable information such as your
                  name, email address, and other contact details.
                </li>
                <li className="list-group-item list-group-item-info">
                  1.2 Non-Personal Information:<br/>
                  - We may collect non-personal information, such as browser information, IP addresses, and cookies, to
                  enhance your experience and improve our services.
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <span>2. How We Use Your Information:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  2.1 Personal Information:<br/>
                  - We do not use any personal information.
                </li>
                <li className="list-group-item list-group-item-info">
                  2.2 Non-Personal Information:<br/>
                  - Non-personal information is used to analyze trends, track user movements, and improve our
                  Website&apos;s functionality.
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <span>3. Sharing Your Information:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  3.1 Affiliates and Third Parties:<br/>
                  - We may share non-personal information with affiliated companies and trusted third parties to provide
                  and improve our services, as well as for marketing purposes.
                </li>
                <li className="list-group-item list-group-item-info">
                  3.2 Legal Requirements:<br/>
                  - We may disclose your information if required to do so by law or in response to valid requests by
                  public authorities.
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <span>4. Cookies:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  4.1 Usage of Cookies:<br/>
                  - We use cookies to enhance your browsing experience. You can set your browser to refuse cookies, but
                  some features of the website may not function properly.
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <span>5. Security:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  5.1 Data Security:<br/>
                  - We implement reasonable security measures to protect your non-personal information from unauthorized
                  access, disclosure, alteration, and destruction.
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <span>6. Your Choices:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  6.1 Opt-Out:<br/>
                  - You may choose to opt-out of receiving marketing communications from us by following the
                  instructions
                  provided in such communications.
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <span>7. Changes to this Privacy Policy:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  7.1 Updates:<br/>
                  - We reserve the right to update our Privacy Policy. Any changes will be posted on this page with the
                  revised date.
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <span>8. Contact Us:</span>
              <ul className="list-group list-group-numbered">
                <li className="list-group-item list-group-item-info">
                  8.1 Questions: <br/>
                  - If you have any questions about this Privacy Policy, please contact us at allbrand.site@gmail.com.
                </li>
              </ul>
            </li>
          </ul>
          <span>By using our website, you agree to the terms outlined in this Privacy Policy.</span>
        </section>
      </Container>
      <Footer/>
    </>
  )
}

export default Privacy