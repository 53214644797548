import React from 'react'
import Products from "../components/Products"
import Header from "../components/Header"
import Footer from "../components/Footer"

const HomePage: React.FC = () => {
    return (
        <>
          <Header/>
          <Products/>
          <Footer/>
        </>
    )
}

export default HomePage
