import React from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import {useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import Products from "../components/Products";

const BrandPage: React.FC = () => {
    const {id} = useParams()
    return (
        <>
          <Header/>
            <Container>
                <div>Brand Page {id}</div>
            </Container>

          <Footer/>
        </>
    )
}

export default BrandPage
