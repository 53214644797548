import React, {useEffect, useState} from 'react'
import {IAdvertiser} from "../types/model"
import {Col, Container, Row} from "react-bootstrap"
import {loadAdvertisers} from "../store/cj"
import {Link} from "react-router-dom";


const Brands: React.FC = () => {
    const [advs, setAdvs] = useState<IAdvertiser[]>([])

    useEffect(() => {
        loadAdvertisers().then(a => {
            setAdvs(a)
        })
    }, [])

    return (
        <Container className="container container-fluid d-flex">
            <Row>
                {advs.map((m) => (
                    <Col sm={12} md={3} lg={2} key={m.id} className="my-5 border-secondary justify-content-around">
                        <div>
                            <Link to={`/brand/${m.id}/${m.name.replaceAll(' ', '-')}`}>
                                <img src={`https://members.cj.com/member/publisher/logo/${m.logo}.gif`} alt={m.logo}/>
                            </Link>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default Brands
