export const categories = [
    {
        "id": 411,
        "name": "Accessories",
        "children": [
            {
                "id": 413,
                "name": "Handbags",
                "children": []
            },
            {
                "id": 350,
                "name": "Jewelry",
                "children": []
            },
            {
                "id": 414,
                "name": "Shoes",
                "children": []
            }
        ]
    },
    {
        "id": 304,
        "name": "Art/Photo/Music",
        "children": [
            {
                "id": 305,
                "name": "Art",
                "children": []
            },
            {
                "id": 307,
                "name": "Music",
                "children": []
            },
            {
                "id": 306,
                "name": "Photo",
                "children": []
            }
        ]
    },
    {
        "id": 300,
        "name": "Automotive",
        "children": [
            {
                "id": 302,
                "name": "Cars & Trucks",
                "children": []
            },
            {
                "id": 901,
                "name": "Motorcycles",
                "children": []
            },
            {
                "id": 301,
                "name": "Parts & Accessories",
                "children": []
            },
            {
                "id": 303,
                "name": "Rentals",
                "children": []
            },
            {
                "id": 400,
                "name": "Tools and Supplies",
                "children": []
            }
        ]
    },
    {
        "id": 1054,
        "name": "Beauty",
        "children": [
            {
                "id": 358,
                "name": "Bath & Body",
                "children": []
            },
            {
                "id": 360,
                "name": "Cosmetics",
                "children": []
            },
            {
                "id": 412,
                "name": "Fragrance",
                "children": []
            },
            {
                "id": 1105,
                "name": "Green",
                "children": []
            }
        ]
    },
    {
        "id": 309,
        "name": "Books/Media",
        "children": [
            {
                "id": 881,
                "name": "Audio Books",
                "children": []
            },
            {
                "id": 310,
                "name": "Books",
                "children": []
            },
            {
                "id": 311,
                "name": "Magazines",
                "children": []
            },
            {
                "id": 312,
                "name": "News",
                "children": []
            },
            {
                "id": 501,
                "name": "Television",
                "children": []
            },
            {
                "id": 313,
                "name": "Videos/Movies",
                "children": []
            }
        ]
    },
    {
        "id": 315,
        "name": "Business",
        "children": [
            {
                "id": 581,
                "name": "Business-to-Business",
                "children": []
            },
            {
                "id": 316,
                "name": "Marketing",
                "children": []
            },
            {
                "id": 317,
                "name": "Office",
                "children": []
            },
            {
                "id": 393,
                "name": "Productivity Tools",
                "children": []
            },
            {
                "id": 395,
                "name": "Travel",
                "children": []
            }
        ]
    },
    {
        "id": 326,
        "name": "Buying and Selling",
        "children": [
            {
                "id": 327,
                "name": "Auction",
                "children": []
            },
            {
                "id": 328,
                "name": "Classifieds",
                "children": []
            },
            {
                "id": 329,
                "name": "E-commerce Solutions/Providers",
                "children": []
            },
            {
                "id": 641,
                "name": "New/Used Goods",
                "children": []
            }
        ]
    },
    {
        "id": 318,
        "name": "Careers",
        "children": [
            {
                "id": 319,
                "name": "Employment",
                "children": []
            },
            {
                "id": 521,
                "name": "Military",
                "children": []
            }
        ]
    },
    {
        "id": 321,
        "name": "Clothing/Apparel",
        "children": [
            {
                "id": 324,
                "name": "Childrens",
                "children": []
            },
            {
                "id": 1106,
                "name": "Green",
                "children": []
            },
            {
                "id": 325,
                "name": "Malls",
                "children": []
            },
            {
                "id": 322,
                "name": "Mens",
                "children": []
            },
            {
                "id": 323,
                "name": "Womens",
                "children": []
            }
        ]
    },
    {
        "id": 331,
        "name": "Computer & Electronics",
        "children": [
            {
                "id": 332,
                "name": "Computer HW",
                "children": []
            },
            {
                "id": 333,
                "name": "Computer SW",
                "children": []
            },
            {
                "id": 416,
                "name": "Computer Support",
                "children": []
            },
            {
                "id": 397,
                "name": "Consumer Electronics",
                "children": []
            },
            {
                "id": 1107,
                "name": "Green",
                "children": []
            },
            {
                "id": 334,
                "name": "Peripherals",
                "children": []
            }
        ]
    },
    {
        "id": 375,
        "name": "Department Stores/Malls",
        "children": [
            {
                "id": 1101,
                "name": "Department Stores",
                "children": []
            },
            {
                "id": 376,
                "name": "Virtual Malls",
                "children": []
            }
        ]
    },
    {
        "id": 419,
        "name": "Education",
        "children": [
            {
                "id": 420,
                "name": "Children",
                "children": []
            },
            {
                "id": 320,
                "name": "College",
                "children": []
            },
            {
                "id": 601,
                "name": "Languages",
                "children": []
            },
            {
                "id": 421,
                "name": "Professional",
                "children": []
            }
        ]
    },
    {
        "id": 941,
        "name": "Entertainment",
        "children": [
            {
                "id": 1040,
                "name": "Discounts",
                "children": []
            },
            {
                "id": 762,
                "name": "Events",
                "children": []
            },
            {
                "id": 1039,
                "name": "Guides",
                "children": []
            },
            {
                "id": 942,
                "name": "Memorabilia",
                "children": []
            },
            {
                "id": 1041,
                "name": "Mobile Entertainment",
                "children": []
            },
            {
                "id": 681,
                "name": "Party Goods",
                "children": []
            }
        ]
    },
    {
        "id": 403,
        "name": "Family",
        "children": [
            {
                "id": 415,
                "name": "Babies",
                "children": []
            },
            {
                "id": 404,
                "name": "Children",
                "children": []
            },
            {
                "id": 406,
                "name": "Entertainment",
                "children": []
            },
            {
                "id": 405,
                "name": "Teens",
                "children": []
            },
            {
                "id": 481,
                "name": "Weddings",
                "children": []
            }
        ]
    },
    {
        "id": 335,
        "name": "Financial Services",
        "children": [
            {
                "id": 336,
                "name": "Banking/Trading",
                "children": []
            },
            {
                "id": 337,
                "name": "Credit Cards",
                "children": []
            },
            {
                "id": 1001,
                "name": "Credit Reporting and Repair",
                "children": []
            },
            {
                "id": 390,
                "name": "Investment",
                "children": []
            },
            {
                "id": 339,
                "name": "Mortgage Loans",
                "children": []
            },
            {
                "id": 1038,
                "name": "Personal Loans",
                "children": []
            },
            {
                "id": 340,
                "name": "Real Estate Services",
                "children": []
            },
            {
                "id": 1037,
                "name": "Tax Services",
                "children": []
            }
        ]
    },
    {
        "id": 341,
        "name": "Food & Drinks",
        "children": [
            {
                "id": 342,
                "name": "Gourmet",
                "children": []
            },
            {
                "id": 1108,
                "name": "Green",
                "children": []
            },
            {
                "id": 345,
                "name": "Groceries",
                "children": []
            },
            {
                "id": 346,
                "name": "Restaurants",
                "children": []
            },
            {
                "id": 343,
                "name": "Wine & Spirits",
                "children": []
            }
        ]
    },
    {
        "id": 353,
        "name": "Games & Toys",
        "children": [
            {
                "id": 1046,
                "name": "Electronic Games",
                "children": []
            },
            {
                "id": 355,
                "name": "Electronic Toys",
                "children": []
            },
            {
                "id": 354,
                "name": "Games",
                "children": []
            },
            {
                "id": 356,
                "name": "Toys",
                "children": []
            }
        ]
    },
    {
        "id": 348,
        "name": "Gifts & Flowers",
        "children": [
            {
                "id": 402,
                "name": "Collectibles",
                "children": []
            },
            {
                "id": 351,
                "name": "Flowers",
                "children": []
            },
            {
                "id": 349,
                "name": "Gifts",
                "children": []
            },
            {
                "id": 1109,
                "name": "Green",
                "children": []
            },
            {
                "id": 352,
                "name": "Greeting Cards",
                "children": []
            }
        ]
    },
    {
        "id": 357,
        "name": "Health and Wellness",
        "children": [
            {
                "id": 408,
                "name": "Equipment",
                "children": []
            },
            {
                "id": 1110,
                "name": "Green",
                "children": []
            },
            {
                "id": 359,
                "name": "Health Food",
                "children": []
            },
            {
                "id": 361,
                "name": "Nutritional Supplements",
                "children": []
            },
            {
                "id": 362,
                "name": "Pharmaceuticals",
                "children": []
            },
            {
                "id": 741,
                "name": "Self Help",
                "children": []
            },
            {
                "id": 961,
                "name": "Vision Care",
                "children": []
            },
            {
                "id": 1047,
                "name": "Weight Loss",
                "children": []
            },
            {
                "id": 409,
                "name": "Wellness",
                "children": []
            }
        ]
    },
    {
        "id": 368,
        "name": "Home & Garden",
        "children": [
            {
                "id": 369,
                "name": "Bed & Bath",
                "children": []
            },
            {
                "id": 418,
                "name": "Construction",
                "children": []
            },
            {
                "id": 1102,
                "name": "Energy Saving",
                "children": []
            },
            {
                "id": 661,
                "name": "Furniture",
                "children": []
            },
            {
                "id": 370,
                "name": "Garden",
                "children": []
            },
            {
                "id": 1104,
                "name": "Green",
                "children": []
            },
            {
                "id": 388,
                "name": "Home Appliances",
                "children": []
            },
            {
                "id": 561,
                "name": "Kitchen",
                "children": []
            },
            {
                "id": 371,
                "name": "Pets",
                "children": []
            },
            {
                "id": 417,
                "name": "Real Estate",
                "children": []
            },
            {
                "id": 1103,
                "name": "Recycling",
                "children": []
            },
            {
                "id": 401,
                "name": "Utilities",
                "children": []
            }
        ]
    },
    {
        "id": 398,
        "name": "Insurance",
        "children": [
            {
                "id": 389,
                "name": "Commercial",
                "children": []
            },
            {
                "id": 399,
                "name": "Personal Insurance",
                "children": []
            }
        ]
    },
    {
        "id": 801,
        "name": "Legal",
        "children": [
            {
                "id": 802,
                "name": "Services",
                "children": []
            }
        ]
    },
    {
        "id": 391,
        "name": "Marketing",
        "children": [
            {
                "id": 392,
                "name": "Business-to-Business",
                "children": []
            },
            {
                "id": 410,
                "name": "Network Marketing",
                "children": []
            }
        ]
    },
    {
        "id": 541,
        "name": "Military",
        "children": []
    },
    {
        "id": 9999,
        "name": "N/A",
        "children": []
    },
    {
        "id": 1043,
        "name": "Non-Profit",
        "children": [
            {
                "id": 1044,
                "name": "Charitable Organizations",
                "children": []
            },
            {
                "id": 1045,
                "name": "Fundraising",
                "children": []
            }
        ]
    },
    {
        "id": 382,
        "name": "Online Services",
        "children": [
            {
                "id": 1022,
                "name": "Blogs",
                "children": []
            },
            {
                "id": 1023,
                "name": "Broadband",
                "children": []
            },
            {
                "id": 461,
                "name": "Domain Registrations",
                "children": []
            },
            {
                "id": 981,
                "name": "Email Marketing",
                "children": []
            },
            {
                "id": 701,
                "name": "Internet Service Providers",
                "children": []
            },
            {
                "id": 384,
                "name": "Search Engine",
                "children": []
            },
            {
                "id": 386,
                "name": "Web Design",
                "children": []
            },
            {
                "id": 385,
                "name": "Web Hosting/Servers",
                "children": []
            },
            {
                "id": 387,
                "name": "Web Tools",
                "children": []
            }
        ]
    },
    {
        "id": 363,
        "name": "Recreation & Leisure",
        "children": [
            {
                "id": 364,
                "name": "Astrology",
                "children": []
            },
            {
                "id": 365,
                "name": "Betting/Gaming",
                "children": []
            },
            {
                "id": 1021,
                "name": "Camping and Hiking",
                "children": []
            },
            {
                "id": 367,
                "name": "Communities",
                "children": []
            },
            {
                "id": 1111,
                "name": "Green",
                "children": []
            },
            {
                "id": 366,
                "name": "Matchmaking",
                "children": []
            },
            {
                "id": 621,
                "name": "Outdoors",
                "children": []
            },
            {
                "id": 347,
                "name": "Tobacco",
                "children": []
            }
        ]
    },
    {
        "id": 1024,
        "name": "Seasonal",
        "children": [
            {
                "id": 1025,
                "name": "Autumn",
                "children": []
            },
            {
                "id": 1026,
                "name": "Back to School",
                "children": []
            },
            {
                "id": 1141,
                "name": "Black Friday/Cyber Monday",
                "children": []
            },
            {
                "id": 1027,
                "name": "Christmas",
                "children": []
            },
            {
                "id": 1028,
                "name": "Easter",
                "children": []
            },
            {
                "id": 1029,
                "name": "Fathers Day",
                "children": []
            },
            {
                "id": 1030,
                "name": "Halloween",
                "children": []
            },
            {
                "id": 1031,
                "name": "Mothers Day",
                "children": []
            },
            {
                "id": 1142,
                "name": "New Years Resolution",
                "children": []
            },
            {
                "id": 1032,
                "name": "Spring",
                "children": []
            },
            {
                "id": 1033,
                "name": "Summer",
                "children": []
            },
            {
                "id": 1034,
                "name": "Tax Season",
                "children": []
            },
            {
                "id": 1035,
                "name": "Valentines Day",
                "children": []
            },
            {
                "id": 1036,
                "name": "Winter",
                "children": []
            }
        ]
    },
    {
        "id": 372,
        "name": "Sports & Fitness",
        "children": [
            {
                "id": 1048,
                "name": "Apparel",
                "children": []
            },
            {
                "id": 1053,
                "name": "Collectibles and Memorabilia",
                "children": []
            },
            {
                "id": 1049,
                "name": "Equipment",
                "children": []
            },
            {
                "id": 374,
                "name": "Exercise & Health",
                "children": []
            },
            {
                "id": 1052,
                "name": "Golf",
                "children": []
            },
            {
                "id": 921,
                "name": "Professional Sports Organizations",
                "children": []
            },
            {
                "id": 373,
                "name": "Sports",
                "children": []
            },
            {
                "id": 1051,
                "name": "Summer Sports",
                "children": []
            },
            {
                "id": 441,
                "name": "Water Sports",
                "children": []
            },
            {
                "id": 1050,
                "name": "Winter Sports",
                "children": []
            }
        ]
    },
    {
        "id": 821,
        "name": "Telecommunications",
        "children": [
            {
                "id": 822,
                "name": "Online/Wireless",
                "children": []
            },
            {
                "id": 1042,
                "name": "Phone Card Services",
                "children": []
            },
            {
                "id": 330,
                "name": "Telephone Services",
                "children": []
            }
        ]
    },
    {
        "id": 377,
        "name": "Travel",
        "children": [
            {
                "id": 394,
                "name": "Accessories",
                "children": []
            },
            {
                "id": 378,
                "name": "Air",
                "children": []
            },
            {
                "id": 379,
                "name": "Car",
                "children": []
            },
            {
                "id": 1112,
                "name": "Green",
                "children": []
            },
            {
                "id": 380,
                "name": "Hotel",
                "children": []
            },
            {
                "id": 396,
                "name": "Luggage",
                "children": []
            },
            {
                "id": 381,
                "name": "Vacation",
                "children": []
            }
        ]
    }
    ]