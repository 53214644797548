import React, {useEffect, useState} from 'react'
import {gql} from '@apollo/client';
import {Button, Col, Container, InputGroup, Row, Form, Spinner} from "react-bootstrap"
import {client} from "../resources/apollo"
import {CJ_CID, CJ_WID} from "../resources/api-constants"
import {IProduct} from "../types/model"
import ProductCard from "./ProductCard"

const getQuery = (offset=0, brand='', category='', keyword='', partnerId=0) => {
    const kw = keyword ? `, keywords: ["${keyword}"]` : ''
    const partners = partnerId > 0 ? `, partnerIds: [${partnerId}]` : ''
    return gql`
  {
      products(companyId: ${CJ_CID}, limit: 50, offset: ${offset}, partnerStatus: JOINED ${partners} ${kw}) {
        totalCount
        count
        limit
        resultList {
            id
            advertiserName
            brand
            lastUpdated
            imageLink
            additionalImageLink
            linkCode(pid: ${CJ_WID}, shopperId: ${CJ_CID}) {
              clickUrl
            }
            title
            price {
              amount
              currency
            }
            salePrice {
              amount
              currency
            }
            isDeleted
        }
    }
}
`
}

interface IProductSearch {
    brand?: string
    category?: string
    partnerId?: number
}

const Products: React.FC = ({brand, category, partnerId}: IProductSearch) => {
    const [products, setProducts] = useState<IProduct[]>([])
    const [count, setCount] =useState(0)
    const [offset, setOffset] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        fetchProducts()
    }, [])

    useEffect(() => {
        fetchProducts()
    }, [offset])

    const fetchProducts = () => {
        setLoading(true)
        client.query({
            query: getQuery(offset, brand, category, keyword, partnerId),
        }).then((result) => {
            setCount(result.data.products.totalCount)
            if (offset == 1) {
                setProducts(result.data.products.resultList)
            } else {
                setProducts([...products, ...result.data.products.resultList])
            }

        }).catch((err) => setError(err))
            .finally(() => {setLoading(false)})
    }


  return (
      <>
          <nav className="py-2 bg-body-tertiary border-bottom sticky-top mb-2">
              <div className="container d-flex flex-wrap align-content-end">
                  <ul className="nav me-auto">
                      <li className="nav-item"><a href="/marketplaces" className="nav-link link-body-emphasis px-2 active"
                                                  aria-current="page">Marketplaces</a></li>
                      <li className="nav-item"><a href="/brands" className="nav-link link-body-emphasis px-2">Brands</a>
                      </li>
                      <li className="nav-item"><a href="/categories"
                                                  className="nav-link link-body-emphasis px-2">Categories</a></li>
                  </ul>
                  <InputGroup className="mb-2">
                      <Form.Control placeholder="Search..." aria-label="Search..."
                                    aria-describedby="basic-addon1" id="search_input"
                                    value={keyword} onChange={(e) =>setKeyword(e.target.value)}/>
                      <InputGroup.Text>Found {count}</InputGroup.Text>
                      <Button id="basic-addon1" onClick={fetchProducts} disabled={loading}>Search</Button>
                      <Button id="basic-addon2" onClick={() => {setKeyword(''); setOffset(1)}} className="btn btn-danger">Clear</Button>
                  </InputGroup>
              </div>
          </nav>
          <Container className="container container-fluid d-flex">
              {error && <Row><Col>Oops... Please reload the page {error}</Col></Row>}
              {loading &&
                  <Col>
                      <Spinner variant="danger" animation="grow" size="sm"/>
                      <Spinner variant="warning" animation="grow"/>
                      <Spinner variant="success" animation="grow"/>
                  </Col>
              }
              <Row>
                  {products.map((p, index) => (
                    <Col key={p.id+index} lg={3} md={4} sm={6} xs={12} className="mb-3">
                        <ProductCard product={p}/>
                    </Col>
                  ))}
                  {count > products.length &&
                      <Col>
                          <Button className="btn btn-outline-success btn-light" onClick={() => {setOffset(offset * 50)}} disabled={loading}>Load more</Button>
                      </Col>
                  }
              </Row>
          </Container>
      </>
  )
}

export default Products