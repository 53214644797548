import axios from "axios"
import {CJ_API_URL, CJ_TOKEN, CJ_WID} from "../resources/api-constants"
import {IAdvertiser, ICategory} from "../types/model";
import {advertisers} from "./advertisers";
import {categories} from "./categories";

export const  fetchBrands = async () => {
    const endpoint = `${CJ_API_URL}/v2/advertiser-lookup?website-id=${CJ_WID}&advertiser-ids=joined`
    // const endpoint = `https://advertiser-lookup.api.cj.com/v2/advertiser-lookup?requestor-cid=6918580&advertiser-ids=joined`
    const headers = {
        'Authorization': `Bearer ${CJ_TOKEN}`
    }

    const r = await axios.get(endpoint, {'headers': headers})
    console.log('--r', r)
    return Promise.resolve()
}

export const loadAdvertisers = async () => {
    const lastUpdated = localStorage.getItem('lastUpdated');
    let advs: IAdvertiser[] = []
    if (!lastUpdated) {
        const lu = new Date()
        localStorage.setItem('lastUpdated', lu.toString())
        advs = buildAdvertisers()
        localStorage.setItem('advertisers', JSON.stringify(advs))
    } else {
        const now = new Date()
        const lu = new Date(lastUpdated)
        const daysPassed = (now.getTime() - lu.getTime())/1000/60/60/24
        const advs_storage = localStorage.getItem('advertisers')
        if (daysPassed <= 2 && advs_storage) {
            advs = JSON.parse(advs_storage)
        } else {
            advs = buildAdvertisers()
            localStorage.setItem('advertisers', JSON.stringify(advs))
        }
    }
    return Promise.resolve(advs)
}

const buildAdvertisers = (): IAdvertiser[] => {
    const advs: IAdvertiser[] = []
    for (let i = 0; i < (advertisers.filter(l => l.joined)).length; i++) {
        const adv = advertisers[i]
        advs.push({
            id: adv.advertiserId,
            name: adv.advertiserName,
            url: adv.advertiserUrl,
            logo: `https://members.cj.com/member/publisher/logo/${adv.logoId}.gif`,
            keywords: adv.keywords,
            description: adv.programDescription,
            categories: getCategories(adv.categoryIds),
            category: getCategories([adv.categoryId])[0]
        })
    }
    return advs
}


const getCategories = (category_ids: number[]): ICategory[] => {
    return categories.filter(c=> category_ids.includes(c.id))
}