import React from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Brands from "../components/Brands"

const BrandsPage: React.FC = () => {
    return (
        <>
          <Header/>
            <Brands/>
          <Footer/>
        </>
    )
}

export default BrandsPage
