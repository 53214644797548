import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import {IProduct} from "../types/model";
import {Link} from "react-router-dom";

interface Props {
    product: IProduct
}

const Price: React.FC<Props> = ({product}: Props) => {
  if (product.salePrice) {
    return (
      <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <Col>
          {product.price?.currency === 'USD' && <del>${product.price?.amount}</del>}
          {product.price?.currency !== 'USD' && <del>{product.price?.amount} {product.price?.currency}</del>}
        </Col>
        <Col>
          {product.salePrice.currency === 'USD' && <b>${product.salePrice.amount}</b>}
          {product.salePrice.currency !== 'USD' && <b>{product.salePrice.amount} {product.salePrice.currency}</b>}
        </Col>
      </Row>
    )
  }
  return (
    <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <Col></Col>
      <Col>
        {product.price?.currency === 'USD' && <b>${product.price?.amount}</b>}
        {product.price?.currency !== 'USD' && <b>{product.price?.amount} {product.price?.currency}</b>}
      </Col>
    </Row>
  )
}

const ProductCard: React.FC<Props> = ({product}: Props) => {
    return (
        <Card>
            <Card.Img variant="top" src={product.imageLink} className="img-fluid"
                      onError={(e) => {e.currentTarget.src='https://placehold.co/200x230?text=Image+Not+Found'}}/>
            <Card.Body>
              <Card.Title>
                <Link to={product.linkCode.clickUrl} target="_blank" color={"secondary"}>{product.title}</Link>
              </Card.Title>
                <Card.Subtitle>{product.brand}</Card.Subtitle>
            </Card.Body>
            <Card.Footer>
              <Price product={product}/>
            </Card.Footer>
        </Card>
    )
}

export default ProductCard