const baseUrl = 'http://exampleurl'

export const getData = (userId: number): string => {
    return baseUrl + '/data/' + userId
}

export const CJ_URL = 'https://ads.api.cj.com/query'
export const CJ_TOKEN = '2kwa1tkrmbaxa1bpx6b853k7wb'
export const CJ_CID = 6918580
export const CJ_WID = 101074759
export const CJ_API_URL = 'https://advertiser-lookup.api.cj.com'