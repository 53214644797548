import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import 'bootstrap/dist/css/bootstrap.min.css'
import Privacy from "./components/Privacy";
import ComingSoon from "./pages/ComingSoon";
import BrandsPage from "./pages/BrandsPage";
import BrandPage from "./pages/BrandPage";

const RootComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                <Route path={ROUTES.PRIVACY} element={<Privacy/>} />
                <Route path={ROUTES.BRANDS} element={<BrandsPage/>} />
                <Route path={ROUTES.BRAND} element={<BrandPage/>} />
                <Route path={ROUTES.MARKETPLACES} element={<ComingSoon/>} />
                <Route path={ROUTES.CATEGORIES} element={<ComingSoon/>} />
            </Routes>
        </Router>
    )
}

export default RootComponent
