import React from 'react'
import allbrand from '../images/allbrand.png'

const Header: React.FC = () => {
    return (
        <header className="py-3 mb-1 bg-dark">
            <div className="container d-flex flex-wrap justify-content-center">
                <a href="/"
                   className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none">
                    <img src={allbrand} alt="AllBrand"/>
                </a>
                <span className="text-light">
                      Discover Brands, Shop Smart – Your Ultimate Destination for Every Trend!
                </span>
            </div>
        </header>
    )
}

export default Header
